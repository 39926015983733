// @flow
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
  },
};

class Blog_2016_09_18_Bensen_Creek extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <CanyonRatingCard rating="3CI" title="Bensen Creek" />
        <p>
          Sunday morning following the Zion Canyoneering Rendezvous we had some
          time so we decided to descend Benson Creek near Brian Head.
        </p>
        <p>
          After a short, miserable <GlossaryLink>approach</GlossaryLink> we made
          our way down to the top of the{' '}
          <GlossaryLink>technical section</GlossaryLink>. We ate a snacks and
          geared up at the top of rap 1. I put on my bright orange rain jacket,
          telling Corey that I didn’t want my chest to get wet. In reality both
          my shirt and pants were slightly different shades of green so it would
          have been unfashionable and embarrassing if other canyoneers saw me.
        </p>
        <Image caption="My ears were nice and dry" image={data._1} />
        <Image caption="Pre-rap snack" image={data._2} />
        <p>
          I love canyons with flowing streams! I don’t know what it is, but the
          streams make the canyons so beautiful. No more words, just enjoy the
          view.
        </p>
        <Image caption="Rappel 1" image={data._3} />
        <Image caption="Rappel 2" image={data._4} />
        <Image caption="Short hiking section between rappels" image={data._5} />
        <Image caption="Final rappel" image={data._6} />
        <Image image={data._7} />
        <Image image={data._8} />
        <p>
          After the canyon ended and we were hiking back, I convinced Corey to
          run all the way back with me. He spread his arms out like bird’s
          wings. It made it more fun.
        </p>
        <Image caption="Corey flying back to the car" image={data._9} />
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2016-09-18-bensen-creek')(
  Blog_2016_09_18_Bensen_Creek
);

export const query = graphql`
  query {
    _1: file(
      relativePath: { eq: "images/blog/2016-09-18-bensen-creek/1.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: { eq: "images/blog/2016-09-18-bensen-creek/2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: { eq: "images/blog/2016-09-18-bensen-creek/3.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: { eq: "images/blog/2016-09-18-bensen-creek/4.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: { eq: "images/blog/2016-09-18-bensen-creek/5.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: { eq: "images/blog/2016-09-18-bensen-creek/6.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: { eq: "images/blog/2016-09-18-bensen-creek/7.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(
      relativePath: { eq: "images/blog/2016-09-18-bensen-creek/8.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(
      relativePath: { eq: "images/blog/2016-09-18-bensen-creek/9.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
